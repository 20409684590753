import React from 'react';
import { hot } from 'react-hot-loader';
import { EmptyProps, EmptyState } from '../../common/interfaces/empty';
import './style.scss';

class About extends React.Component<EmptyProps, EmptyState> {
  render() {
    return (
      <>
        <img className='img-fluid rounded image' src='images/balloons.png' />
        <h4>Happy birthday Charlie!</h4>
      </>
    );
  }
}

export default hot(module)(About);
