import React from 'react';
import { hot } from 'react-hot-loader';
import { EmptyProps, EmptyState } from '../../common/interfaces/empty';
import Content from '../Content';
import Header from '../Header';
import './style.scss';

class App extends React.Component<EmptyProps, EmptyState> {
  render() {
    window.scrollTo(0, 0);

    return (
      <div className='container-md'>
        <Header />
        <Content />
      </div>
    );
  }
}

export default hot(module)(App);
